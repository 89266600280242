.rightSide {
  justify-content: center;
  align-items: center;
  flex-grow: 10;
  padding: 20px;
  padding-top: 30px;
  padding-left: 30px;
  background: lightgray;
  background: var(--color-depth4);
  overflow: scroll;
}

.rinfo {
  padding: 10px;
}

.infoBox {
  width: 100%;
  margin: 10px;
  border-width: 1px;
  border-color: lightgrey;
  border-style: dotted;
  min-height: 300px;
}

.cardAlign {
  display: flex;
  flex-direction: column;
}

.textBox {
  border-color: grey;
  width: 100%;
  height: 370px;
  max-height: 370px;
  padding: 10px;
}

.toggleGroup {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.toggleButton {
  width: 100%;
}

.rinfoTabpanel {
  padding: 10px;
  background: red;
}
