.list {
  display: flex;
  flex-direction: column;
  max-height: 300px;

  overflow: scroll;

  margin-bottom: 15px;
}

.listElement {
  display: flex;
  justify-content: flex-end;

  flex-direction: row;
  flex-grow: 10;
  padding: 5px;
}
