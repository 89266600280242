.container {
  padding: 30px;
  margin: 15px;
  overflow: scroll;
  flex-grow: 10;
}

.root {
  padding-top: 30px;
  padding-left: 30px;

  width: 80%;
}

.field {
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
}

.submit {
  background-color: #0bdbc5;
  align-items: center;
  border: 0;
  border-radius: 1;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
  width: 100%;
}
