@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  transition: background-color 100ms ease;
  transition: color 100ms ease;
  transition: border-color 100ms ease;
  font-family: 'Roboto', sans-serif;
}

:root {
  --color-bg: #e0dede;
  --color-depth1: #ffffff;
  --color-depth2: #f2f6f6;
  --color-depth3: #e6eeee;
  --color-depth4: #dae5e6;
  --color-depth5: #ceddde;
  --color-depth6: #c1d4d5;
  --color-depth7: #b5cbcd;
  --color-depth8: #90b1b4;
  --color-depth9: #84a9ac;
  --color-secondaryPale: #ff7597;
  --color-secondary: #f50057;
  --color-primary: #10ccb9;
  --color-error: #cf6679;
  --color-alert: #017957;
  --color-active: #bb014e;
  --color-onPrimary: rgba(37, 38, 39, 0.87);
  --color-onSecondary: #ffffff;
  --color-text: rgba(37, 38, 39, 0.87);
  --color-textHinted: rgba(37, 38, 39, 0.38);
  --button-outline: rgba(0, 0, 0, 0.23);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-Background {
  height: 100vh;
  width: 100vw;
  background: var(--color-depth2);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.RestaurantInformation_rightSide__2t2uK {
  justify-content: center;
  align-items: center;
  flex-grow: 10;
  padding: 20px;
  padding-top: 30px;
  padding-left: 30px;
  background: lightgray;
  background: var(--color-depth4);
  overflow: scroll;
}

.RestaurantInformation_rinfo__pl5Da {
  padding: 10px;
}

.RestaurantInformation_infoBox__3PKa5 {
  width: 100%;
  margin: 10px;
  border-width: 1px;
  border-color: lightgrey;
  border-style: dotted;
  min-height: 300px;
}

.RestaurantInformation_cardAlign__3RLdQ {
  display: flex;
  flex-direction: column;
}

.RestaurantInformation_textBox__25qjt {
  border-color: grey;
  width: 100%;
  height: 370px;
  max-height: 370px;
  padding: 10px;
}

.RestaurantInformation_toggleGroup__2LQmk {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.RestaurantInformation_toggleButton__1mLwC {
  width: 100%;
}

.RestaurantInformation_rinfoTabpanel__174ji {
  padding: 10px;
  background: red;
}

.NoteBox_list__2Iv8a {
  display: flex;
  flex-direction: column;
  max-height: 300px;

  overflow: scroll;

  margin-bottom: 15px;
}

.NoteBox_listElement__1lxyT {
  display: flex;
  justify-content: flex-end;

  flex-direction: row;
  flex-grow: 10;
  padding: 5px;
}

.RestaurantList_rlist__fzirw {
  height: 100%;
  width: 300px;
  overflow-y: scroll;
}

.RestaurantList_link__3wSWa {
  border-radius: 1;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.3);
  background: white;
}

.RestaurantList_selected__Gau9z {
  background-color: red im !important;
}

.RestaurantList_expand__3zGzM {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: -10px;
  background: white;
  z-index: 2;
  padding-top: 20px;
}

.Dashboard_container__2XEO4 {
  height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
  background: var(--color-depth1);
}

.OnBoarding_container__39mGP {
  padding: 30px;
  margin: 15px;
  overflow: scroll;
  flex-grow: 10;
}

.OnBoarding_root__12nO7 {
  padding-top: 30px;
  padding-left: 30px;

  width: 80%;
}

.OnBoarding_field__1h8wn {
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
}

.OnBoarding_submit__3hzjV {
  background-color: #0bdbc5;
  align-items: center;
  border: 0;
  border-radius: 1;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
  width: 100%;
}

.ErrorList_header__2AeXj {
  display: flex;
  height: 80px;
  width: 100vw;
  background: grey;
  align-items: center;
  justify-content: center;
}

.ErrorList_container__2AYEV {
  height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
}

.ErrorList_leftSide__1__Hn {
  width: 400px;
  height: 100%;
  background: greenyellow;
}

.ErrorList_treeRoot__3eWmO {
  color: red;
}

.ErrorList_labelRoot__1i79S {
  display: flex;
  align-items: center;
  padding: 10;
}

.ErrorList_labelIcon__3osC- {
  margin: 10;
}

.ErrorList_labelText__3_mfb {
  flex-grow: 2;
}

.ErrorList_rightSide__27HiA {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 10;
  padding: 10px;
  height: 100%;

  background: rgb(54, 39, 138);
}

.ErrorList_rightUpper__2FFsp {
  display: flex;
  width: 90%;
  flex-grow: 1;
  border-style: dotted;
  align-items: center;
  justify-content: center;
}

.ErrorList_graph__33nVo {
}

.ErrorList_rightLower__3gMzf {
  display: flex;
  background: dodgerblue;

  width: 90%;
  flex-grow: 2;
  border-style: dotted;
}

