@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  transition: background-color 100ms ease;
  transition: color 100ms ease;
  transition: border-color 100ms ease;
  font-family: 'Roboto', sans-serif;
}

:root {
  --color-bg: #e0dede;
  --color-depth1: #ffffff;
  --color-depth2: #f2f6f6;
  --color-depth3: #e6eeee;
  --color-depth4: #dae5e6;
  --color-depth5: #ceddde;
  --color-depth6: #c1d4d5;
  --color-depth7: #b5cbcd;
  --color-depth8: #90b1b4;
  --color-depth9: #84a9ac;
  --color-secondaryPale: #ff7597;
  --color-secondary: #f50057;
  --color-primary: #10ccb9;
  --color-error: #cf6679;
  --color-alert: #017957;
  --color-active: #bb014e;
  --color-onPrimary: rgba(37, 38, 39, 0.87);
  --color-onSecondary: #ffffff;
  --color-text: rgba(37, 38, 39, 0.87);
  --color-textHinted: rgba(37, 38, 39, 0.38);
  --button-outline: rgba(0, 0, 0, 0.23);
}
