.rlist {
  height: 100%;
  width: 300px;
  overflow-y: scroll;
}

.link {
  border-radius: 1;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.3);
  background: white;
}

.selected {
  background-color: red im !important;
}

.expand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  position: sticky;
  top: -10px;
  background: white;
  z-index: 2;
  padding-top: 20px;
}
