.header {
  display: flex;
  height: 80px;
  width: 100vw;
  background: grey;
  align-items: center;
  justify-content: center;
}

.container {
  height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
}

.leftSide {
  width: 400px;
  height: 100%;
  background: greenyellow;
}

.treeRoot {
  color: red;
}

.labelRoot {
  display: flex;
  align-items: center;
  padding: 10;
}

.labelIcon {
  margin: 10;
}

.labelText {
  flex-grow: 2;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 10;
  padding: 10px;
  height: 100%;

  background: rgb(54, 39, 138);
}

.rightUpper {
  display: flex;
  width: 90%;
  flex-grow: 1;
  border-style: dotted;
  align-items: center;
  justify-content: center;
}

.graph {
}

.rightLower {
  display: flex;
  background: dodgerblue;

  width: 90%;
  flex-grow: 2;
  border-style: dotted;
}
